<template>
  <div class="lottery-api-config">
    <el-page-header @back="goBack" :content="`${lotteryInfo.name} - 开奖API配置`" />

    <el-card class="api-config">
      <!-- 顶部操作栏 -->
      <div class="toolbar">
        <div class="left-buttons">
          <el-button type="primary" size="medium" @click="addApiConfig">
            <i class="el-icon-plus"></i> 添加API配置
          </el-button>
          <el-button type="warning" size="medium" @click="testAllApis" :loading="testingAll">
            <i class="el-icon-refresh"></i> 测试所有接口
          </el-button>
        </div>
        <el-button type="success" size="medium" @click="saveChanges" :loading="submitting">
          <i class="el-icon-check"></i> 保存修改
        </el-button>
      </div>

      <!-- PC端表格 -->
      <el-table
        v-if="!isMobile"
        :data="apiConfigs"
        style="width: 100%; margin-top: 20px;"
        v-loading="loading"
      >
        <el-table-column label="排序" width="70" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.sort"
              :min="0"
              :max="99"
              size="mini"
              controls-position="right"
              style="width: 60px; text-align: center"
              :controls="false"
              @change="handleSortChange"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column label="API地址" min-width="300">
          <template slot-scope="scope">
            <el-input 
              v-model="scope.row.apiUrl" 
              placeholder="请输入API地址"
              size="small"
              @change="handleDataChange"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="请求方式" width="100" align="center">
          <template slot-scope="scope">
            <el-tag size="small" :type="scope.row.method === 'POST' ? 'warning' : ''">
              {{ scope.row.method }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="时间段" width="320">
          <template slot-scope="scope">
            <div class="time-range">
              <el-time-picker
                v-model="scope.row.startTime"
                size="small"
                placeholder="开始时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                style="width: 110px"
                :prefix-icon="null"
                :clearable="false"
                :picker-options="{
                  format: 'HH:mm:ss'
                }"
                @change="handleDataChange"
              ></el-time-picker>
              <span class="time-separator">至</span>
              <el-time-picker
                v-model="scope.row.endTime"
                size="small"
                placeholder="结束时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                style="width: 110px"
                :prefix-icon="null"
                :clearable="false"
                :picker-options="{
                  format: 'HH:mm:ss'
                }"
                @change="handleDataChange"
              ></el-time-picker>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="handleDataChange"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button 
              type="text" 
              @click="editApiDetail(scope.row)"
            >详细配置</el-button>
            <el-button 
              type="text" 
              class="danger"
              @click="removeApiConfig(scope.$index)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <div v-for="(item, index) in apiConfigs" 
             :key="index" 
             class="mobile-item"
        >
          <div class="mobile-item-header">
            <div class="mobile-item-title">API配置 #{{ index + 1 }}</div>
          </div>
          
          <div class="mobile-item-body">
            <div class="mobile-item-row">
              <span class="label">排序：</span>
              <div class="content">
                <el-input-number
                  v-model="item.sort"
                  :min="0"
                  :max="99"
                  size="mini"
                  controls-position="right"
                  style="width: 60px"
                  :controls="false"
                  @change="handleSortChange"
                ></el-input-number>
              </div>
            </div>

            <div class="mobile-item-row">
              <span class="label">API地址：</span>
              <div class="content">
                <el-input 
                  v-model="item.apiUrl" 
                  placeholder="请输入API地址"
                  size="small"
                  @change="handleDataChange"
                ></el-input>
              </div>
            </div>

            <div class="mobile-item-row">
              <span class="label">请求方式：</span>
              <div class="content">
                <el-tag size="small" :type="item.method === 'POST' ? 'warning' : ''">
                  {{ item.method }}
                </el-tag>
              </div>
            </div>

            <div class="mobile-item-row time-row">
              <span class="label">时间段：</span>
              <div class="content">
                <div class="time-range">
                  <el-time-picker
                    v-model="item.startTime"
                    size="mini"
                    placeholder="开始时间"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    style="width: 110px"
                    :prefix-icon="null"
                    :clearable="false"
                    :picker-options="{
                      format: 'HH:mm:ss'
                    }"
                    @change="handleDataChange"
                  ></el-time-picker>
                  <span class="time-separator">至</span>
                  <el-time-picker
                    v-model="item.endTime"
                    size="mini"
                    placeholder="结束时间"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    style="width: 110px"
                    :prefix-icon="null"
                    :clearable="false"
                    :picker-options="{
                      format: 'HH:mm:ss'
                    }"
                    @change="handleDataChange"
                  ></el-time-picker>
                </div>
              </div>
            </div>

            <div class="mobile-item-row">
              <span class="label">状态：</span>
              <div class="content">
                <el-switch
                  v-model="item.status"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handleDataChange"
                ></el-switch>
              </div>
            </div>
          </div>

          <div class="mobile-item-footer">
            <div class="button-group">
              <el-button type="primary" size="mini" @click="editApiDetail(item)">详细配置</el-button>
              <el-button type="danger" size="mini" @click="removeApiConfig(index)">删除</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- API详细配置表单 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :width="isMobile ? '95%' : '700px'"
      :fullscreen="isMobile"
      :close-on-click-modal="false"
      custom-class="api-detail-dialog"
      @close="resetForm"
    >
      <el-form
        ref="apiDetailForm"
        :model="currentApiConfig"
        :rules="apiDetailRules"
        :label-width="isMobile ? '80px' : '100px'"
        size="small"
      >
        <el-form-item label="请求方式" prop="method">
          <el-radio-group v-model="currentApiConfig.method">
            <el-radio label="GET">GET</el-radio>
            <el-radio label="POST">POST</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="请求头" prop="headers">
          <el-input
            v-model="currentApiConfig.headers"
            type="textarea"
            :rows="4"
            placeholder="请输入请求头(JSON格式)"
          />
          <div class="form-tip">例如：{"Authorization": "Bearer token"}</div>
        </el-form-item>

        <el-form-item label="请求参数" prop="params">
          <el-input
            v-model="currentApiConfig.params"
            type="textarea"
            :rows="4"
            placeholder="请输入请求参数(JSON格式)"
          />
          <div class="form-tip">例如：{"date": "2024-03-20"}</div>
        </el-form-item>

        <el-form-item label="响应解析" prop="responseParser">
          <el-input
            v-model="currentApiConfig.responseParser"
            type="textarea"
            :rows="10"
            placeholder="请输入响应解析规则(JavaScript代码)"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="testApiConfig" :loading="testing">测试配置</el-button>
        </el-form-item>

        <div v-if="testResult" class="test-result">
          <div class="result-header">
            <span>测试结果：</span>
            <el-tag :type="testResult.success ? 'success' : 'danger'" size="small">
              {{ testResult.success ? '成功' : '失败' }}
            </el-tag>
          </div>
          <template v-if="testResult.success">
            <div class="json-block">
              <div class="block-title">原始数据：</div>
              <pre>{{ testResult.originalData }}</pre>
            </div>
            <div class="json-block">
              <div class="block-title">转换后数据：</div>
              <pre>{{ testResult.parsedData }}</pre>
            </div>
          </template>
          <div v-else class="result-error">
            {{ testResult.error }}
          </div>
        </div>
      </el-form>
      <div slot="footer" :class="{'mobile-dialog-footer': isMobile}">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveApiDetail">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加测试结果对话框 -->
    <el-dialog
      title="API测试结果"
      :visible.sync="testResultDialogVisible"
      :width="isMobile ? '95%' : '800px'"
      :fullscreen="isMobile"
      custom-class="test-result-dialog"
    >
      <div v-loading="testingAll" class="test-results">
        <div v-for="result in testResults" :key="result.configId" class="test-result-item">
          <div class="result-header">
            <div class="result-title">
              <span>API配置 #{{result.configId}}</span>
              <el-tag :type="result.success ? 'success' : 'danger'" size="small">
                {{ result.success ? '成功' : '失败' }}
              </el-tag>
            </div>
            <div class="result-url">{{ result.requestInfo?.url || '未知地址' }}</div>
          </div>
          
          <template v-if="result.success">
            <div class="json-block">
              <div class="block-title">
                <span>原始数据：</span>
                <div class="block-actions">
                  <el-button type="text" size="mini" @click="copyToClipboard(result.originalData)">
                    复制
                  </el-button>
                  <el-button type="text" size="mini" @click="result.showOriginal = !result.showOriginal">
                    {{ result.showOriginal ? '收起' : '展开' }}
                  </el-button>
                </div>
              </div>
              <div 
                v-show="result.showOriginal" 
                class="json-preview" 
                v-html="formatJsonPreview(result.originalData)"
                :title="JSON.stringify(result.originalData)"
              ></div>
            </div>
            <div class="json-block">
              <div class="block-title">
                <span>解析数据：</span>
                <div class="block-actions">
                  <el-button type="text" size="mini" @click="copyToClipboard(result.parsedData)">
                    复制
                  </el-button>
                  <el-button type="text" size="mini" @click="result.showParsed = !result.showParsed">
                    {{ result.showParsed ? '收起' : '展开' }}
                  </el-button>
                </div>
              </div>
              <pre v-show="result.showParsed">{{ JSON.stringify(result.parsedData, null, 2) }}</pre>
            </div>
          </template>
          <div v-else class="result-error">
            {{ result.error }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LotteryApiConfig',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lotteryInfo: {
        id: '',
        name: ''
      },
      loading: false,
      submitting: false,
      apiConfigs: [],
      dialogVisible: false,
      dialogTitle: '',
      currentApiConfig: {
        method: 'GET',
        headers: '',
        params: '',
        responseParser: `try {
  if (!response.result?.data) throw new Error('数据格式错误');
  
  const data = response.result.data;
 
  return {
    drawNo: data.preDrawIssue,
    drawTime: data.preDrawTime,
    drawNumbers: data.preDrawCode,
    nextDrawNo: data.drawIssue,
    nextDrawTime: data.drawTime,
    serverTime: data.serverTime,
    attributes: {
      zodiac: data.chineseZodiac || [],
      elements: data.fiveElements || [],
      colors: data.color || []
    }
  };
} catch (error) {
  throw new Error('解析失败: ' + error.message);
}`,
        apiUrl: '',
        startTime: '00:00:00',
        endTime: '23:59:59',
        status: 1,
        sort: 0
      },
      apiDetailRules: {
        method: [
          { required: true, message: '请选择请求方式', trigger: 'change' }
        ],
        responseParser: [
          { required: true, message: '请输入响应解析规则', trigger: 'blur' }
        ]
      },
      testing: false,
      testResult: null,
      hasChanges: false,
      isMobile: false,
      testResultDialogVisible: false,
      testResults: [],
      testingAll: false
    }
  },
  created() {
    this.lotteryInfo = {
      id: this.id,
      name: this.name
    };
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
    this.fetchApiConfigs();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    goBack() {
      if (this.hasChanges) {
        this.$confirm('有未保存的修改，确定要离开吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push('/lottery/list');
        }).catch(() => {});
      } else {
        this.$router.push('/lottery/list');
      }
    },
    // 获取API配置列表
    async fetchApiConfigs() {
      this.loading = true;
      try {
        const res = await this.$http.get(`/api/admin/lottery/${this.lotteryInfo.id}/api-configs`);
        if (res.data.code === 200) {
          this.apiConfigs = res.data.data || [];
        }
      } catch (error) {
        console.error('获取API配置失败:', error);
        this.$message.error('获取API配置失败');
      } finally {
        this.loading = false;
      }
    },

    // 添加API配置
    addApiConfig() {
      this.$prompt('请输入API地址', '添加API配置', {
        confirmButtonText: '下一步',
        cancelButtonText: '取消',
        inputPattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/,
        inputErrorMessage: '请输入有效的URL地址',
        inputPlaceholder: '请输入API地址',
        customClass: 'api-prompt-dialog'
      }).then(({ value }) => {
        this.dialogTitle = '添加API配置';
        this.currentApiConfig = {
          method: 'GET',
          headers: this.currentApiConfig.headers,
          params: '',
          responseParser: this.currentApiConfig.responseParser,
          apiUrl: value,
          startTime: '00:00:00',
          endTime: '23:59:59',
          status: 1,
          sort: this.apiConfigs.length
        };
        this.dialogVisible = true;
      }).catch(() => {
        // 用户取消输入
      });
    },

    // 编辑API配置
    editApiDetail(config) {
      this.dialogTitle = '编辑API配置';
      this.currentApiConfig = { ...config };
      this.dialogVisible = true;
    },

    // 删除API配置
    async removeApiConfig(index) {
      try {
        await this.$confirm('确认删除该API配置吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        
        this.apiConfigs.splice(index, 1);
        this.handleDataChange();
        this.$message.success('删除成功');
      } catch (error) {
        // 用户取消删除
      }
    },

    // 保存API配置
    async saveApiDetail() {
      try {
        await this.$refs.apiDetailForm.validate();
        
        // 验证JSON格式
        if (this.currentApiConfig.headers) {
          JSON.parse(this.currentApiConfig.headers);
        }
        if (this.currentApiConfig.params) {
          JSON.parse(this.currentApiConfig.params);
        }

        const index = this.apiConfigs.findIndex(api => api.sort === this.currentApiConfig.sort);
        if (index > -1) {
          this.apiConfigs[index] = { ...this.currentApiConfig };
        } else {
          this.apiConfigs.push({ ...this.currentApiConfig });
        }

        this.handleDataChange();
        this.dialogVisible = false;
        this.$message.success('保存成功');
      } catch (error) {
        if (error.message) {
          this.$message.error(error.message);
        } else {
          this.$message.error('请检查表单是否填写正确');
        }
      }
    },

    // 保存所有修改
    async saveChanges() {
      try {
        this.submitting = true;
        const res = await this.$http.put(`/api/admin/lottery/${this.lotteryInfo.id}/api-configs`, this.apiConfigs);
        
        if (res.data.code === 200) {
          this.$message.success('保存成功');
          this.hasChanges = false;
          await this.fetchApiConfigs();
        }
      } catch (error) {
        console.error('保存失败:', error);
        this.$message.error('保存失败');
      } finally {
        this.submitting = false;
      }
    },

    // 处理数据变更
    handleDataChange() {
      this.hasChanges = true;
      // 更新排序
      this.apiConfigs.forEach((config, index) => {
        config.sort = index;
      });
    },

    // 处理排序变更
    handleSortChange() {
      this.apiConfigs.sort((a, b) => a.sort - b.sort);
      this.handleDataChange();
    },

    // 测试API配置
    async testApiConfig() {
      if (!this.currentApiConfig.apiUrl) {
        return this.$message.warning('请输入API地址');
      }

      if (!this.currentApiConfig.responseParser) {
        return this.$message.warning('请输入响应解析规则');
      }

      this.testing = true;
      this.testResult = null;

      try {
        // 构建请求选项
        const options = {
          method: this.currentApiConfig.method,
          headers: this.currentApiConfig.headers ? JSON.parse(this.currentApiConfig.headers) : {}
        };

        if (this.currentApiConfig.method === 'POST' && this.currentApiConfig.params) {
          options.body = JSON.stringify(JSON.parse(this.currentApiConfig.params));
        }

        // 发起请求
        const response = await fetch(this.currentApiConfig.apiUrl, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // 执行解析代码
        const parser = new Function('response', `
          try {
            ${this.currentApiConfig.responseParser}
          } catch (error) {
            throw new Error('解析失败: ' + error.message);
          }
        `);
        
        const parsedResult = parser(data);

        this.testResult = {
          success: true,
          originalData: JSON.stringify(data).replace(/"([^"]+)":/g, '$1:'),  // 移除属性名的引号
          parsedData: JSON.stringify(parsedResult).replace(/"([^"]+)":/g, '$1:')  // 移除属性名的引号
        };

      } catch (error) {
        this.testResult = {
          success: false,
          error: error.message || '测试失败'
        };
      } finally {
        this.testing = false;
      }
    },

    // 添加重置表单方法
    resetForm() {
      this.$refs.apiDetailForm?.resetFields();
      this.testResult = null;
    },

    // 添加设备检测方法
    checkDevice() {
      this.isMobile = window.innerWidth < 768;
    },

    // 测试所有API配置
    async testAllApis() {
      if (!this.apiConfigs.length) {
        return this.$message.warning('没有可用的API配置');
      }

      this.testingAll = true;
      this.testResultDialogVisible = true;
      this.testResults = [];

      try {
        const res = await this.$http.get(`/api/admin/lottery/test-api/${this.lotteryInfo.id}`);
        
        if (res.data.code === 200) {
          this.testResults = res.data.data.map(result => ({
            ...result,
            showOriginal: false,
            showParsed: false
          }));
        }
      } catch (error) {
        console.error('测试API失败:', error);
        this.$message.error('测试失败');
      } finally {
        this.testingAll = false;
      }
    },

    // 复制到剪贴板
    copyToClipboard(data) {
      const text = typeof data === 'string' ? data : JSON.stringify(data, null, 2);
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success('复制成功');
      }).catch(() => {
        this.$message.error('复制失败');
      });
    },

    // 格式化JSON预览
    formatJsonPreview(data) {
      return JSON.stringify(data);
    }
  },
  mounted() {
    // 为移动端表格添加标签
    if (this.isMobile) {
      this.$nextTick(() => {
        const cells = document.querySelectorAll('.el-table__cell');
        cells.forEach(cell => {
          const headerText = cell.querySelector('.cell')?.getAttribute('title');
          if (headerText) {
            cell.setAttribute('data-label', headerText);
          }
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-api-config {
  padding: 20px;
  
  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .el-page-header {
    margin-bottom: 20px;
  }

  .api-config {
    .toolbar {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .left-buttons {
        display: flex;
        gap: 12px;
      }
      
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        
        .left-buttons {
          width: 100%;
          flex-direction: column;
        }
        
        .el-button {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    // 移动端卡片列表样式
    .mobile-list {
      .mobile-item {
        margin-bottom: 12px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        
        &:last-child {
          margin-bottom: 0;
        }
        
        .mobile-item-header {
          padding: 12px;
          border-bottom: 1px solid #EBEEF5;
          
          .mobile-item-title {
            font-size: 15px;
            font-weight: 500;
            color: #303133;
            margin-bottom: 4px;
          }
        }
        
        .mobile-item-body {
          padding: 12px;
          
          .mobile-item-row {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            
            &:last-child {
              margin-bottom: 0;
            }
            
            .label {
              width: 70px;
              font-size: 13px;
              color: #606266;
            }

            .content {
              flex: 1;
              
              .time-range {

                align-items: center;
                gap: 8px;

                .el-time-picker {
                  width: calc(50% - 12px) !important;
                }

                .time-separator {
                  flex-shrink: 0;
                  color: #909399;
                }
              }

              .el-input-number {
                width: 120px;
              }
            }
          }
        }
        
        .mobile-item-footer {
          padding: 12px;
          border-top: 1px solid #EBEEF5;

          .button-group {
            display: flex;
            gap: 8px;
            margin-bottom: 8px;
            
            &:last-child {
              margin-bottom: 0;
            }
            
            .el-button {
              flex: 1;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

// API详细配置对话框样式
.api-detail-dialog {
  .form-tip {
    font-size: 12px;
    color: #909399;
    margin-top: 4px;
  }

  .test-result {
    margin-top: 16px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 4px;

    .result-header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }

    .json-block {
      margin-top: 12px;

      .block-title {
        font-size: 13px;
        color: #606266;
        margin-bottom: 4px;
      }

      pre {
        margin: 0;
        padding: 8px;
        background-color: #fff;
        border-radius: 4px;
        font-size: 12px;
        overflow-x: auto;
      }
    }

    .result-error {
      color: #F56C6C;
      font-size: 13px;
    }
  }
}

// 移动端对话框样式
.mobile-dialog-footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 20px;

  .el-button {
    margin: 0;
    width: 100%;
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .lottery-api-config {
    .mobile-list .mobile-item {
      background-color: #1e1e1e;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      
      .mobile-item-header {
        border-bottom-color: #363636;
        
        .mobile-item-title {
          color: #e6e6e6;
        }
      }
      
      .mobile-item-body {
        .mobile-item-row {
          .label {
            color: #909399;
          }
        }
      }
      
      .mobile-item-footer {
        border-top-color: #363636;
      }
    }
  }

  .api-detail-dialog {
    .test-result {
      background-color: #1e1e1e;

      .json-block pre {
        background-color: #2c2c2c;
        color: #e6e6e6;
      }
    }
  }
}

// 时间选择器样式优化
:deep(.el-time-picker) {
  .el-input__inner {
    padding: 0 8px !important;
    text-align: center;
  }

  .el-input__prefix,
  .el-input__suffix,
  .el-input__icon {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      font-size: 13px;
    }
  }
}

// 时间选择下拉面板样式优化
:deep(.el-time-panel) {
  @media screen and (max-width: 768px) {
    width: 140px !important;
    
    .el-time-panel__content {
      width: 100%;
      
      .el-time-spinner__wrapper {
        width: 33.33% !important;
      }
    }
  }
}

.test-result-dialog {
  .test-results {
    .test-result-item {
      margin-bottom: 20px;
      padding: 12px;
      border: 1px solid #EBEEF5;
      border-radius: 4px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .result-header {
        margin-bottom: 12px;
        
        .result-title {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 4px;
          font-weight: 500;
        }
        
        .result-url {
          font-size: 13px;
          color: #909399;
          word-break: break-all;
        }
      }
      
      .json-block {
        margin-top: 12px;
        
        .block-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
          font-size: 13px;
          color: #606266;

          .block-actions {
            display: flex;
            gap: 8px;
          }
        }
        
        .json-preview {
          margin: 0;
          padding: 8px;
          background-color: #f8f9fa;
          border-radius: 4px;
          font-size: 12px;
          font-family: monospace;
          white-space: pre-wrap;
          word-break: break-all;
          cursor: pointer;
          line-height: 1.4;

          &:hover {
            background-color: #ebeef5;
          }
        }
        
        pre {
          margin: 0;
          padding: 8px;
          background-color: #f8f9fa;
          border-radius: 4px;
          font-size: 12px;
          overflow-x: auto;
          font-family: monospace;
        }
      }
      
      .result-error {
        color: #F56C6C;
        font-size: 13px;
      }
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .test-result-dialog {
    .test-results {
      .test-result-item {
        border-color: #4c4c4c;
        background-color: #1e1e1e;
        
        .json-block {
          .json-preview {
            background-color: #2c2c2c;
            color: #e6e6e6;

            &:hover {
              background-color: #363636;
            }
          }
          
          pre {
            background-color: #2c2c2c;
            color: #e6e6e6;
          }
        }
      }
    }
  }
}
</style> 